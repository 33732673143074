<template>
  <div class="form-preview">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{ nameLabel }}</label>
          <p>{{ formName }}</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div role="group" class="form-group" v-if="isMultisite">
          <label for="name" class="form-label">{{ siteLabel }}</label>
          <div class="tags-group">
            <div
              class="tag tags_title"
              v-for="(site, index) in selectedSite"
              :key="'site_' + index"
            >
              <div class="d-flex align-items-center">
                <span class="align-middle">
                  {{ site.siteId }}&nbsp;-&nbsp;{{ site.siteName }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div role="group" class="form-group" v-else>
          <label for="name" class="form-label">{{ siteLabel }}</label>
          <div class="tags-group">
            <div class="tag tags_title">
              <div class="d-flex align-items-center">
                <span class="align-middle">
                  {{ selectedSite.SiteId }}&nbsp;-&nbsp;{{ selectedSite.Name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div role="group" class="form-group" v-if="showProductGroups">
          <label for="name" class="form-label">{{ chooseProductLabel }}</label>
          <div class="product-filters" id="productFilters">
            <div
              v-for="(productGroup, index) in productGroups"
              :key="index"
              :class="{
                'single-filter': !enableProductGroupBoxStyling,
                'product-groups': enableProductGroupBoxStyling,
              }"
            >
              <label
                class="d-block form-label d-flex justify-content-between"
                v-if="enableProductGroupBoxStyling && enableAddProductGroup"
              >
                Product group {{ index + 1 }}
              </label>
              <!--
            Tags Div for Product Group
          -->
              <filtered-tags
                :id="productGroup.groupId"
                :vendors="productGroup.vendor"
                :category="productGroup.category"
                :productTags="productGroup.productTags"
                :vmn="productGroup.productVendorModel"
                :sku="productGroup.productSKU"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12" v-if="showImportCSVFile">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{
            importedFromCSVLabel
          }}</label>
          <p>{{ importCSVFileName }}</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12" v-if="showPriceEnding">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{ priceEndingLabel }}</label>
          <p>{{ priceEnding }}</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12" v-if="showDiscount">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{ discountLabel }}</label>
          <p>{{ discount }}</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12" v-if="showMarginFactor">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{ marginFactorLabel }}</label>
          <p>{{ marginFactor }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12" v-if="showCustomTag">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{ customTagLabel }}</label>
          <p>{{ customTag }}</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12" v-if="showStartDate">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{ startDateLabel }}</label>
          <p>{{ startDate }}</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12" v-if="showEndDate">
        <div role="group" class="form-group">
          <label for="name" class="form-label">{{ endDateLabel }}</label>
          <p>{{ endDate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import { mapGetters } from "vuex";
import FilteredTags from "./FilteredTags.vue";

export default {
  name: "PreviewForm",
  props: {
    formName: String,
    isMultisite: {
      type: Boolean,
      default: false,
    },
    showProductGroups: {
      type: Boolean,
      default: false,
    },
    showPriceEnding: {
      type: Boolean,
      default: false,
    },
    priceEnding: {
      type: [String, Number],
      default: "",
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
    discount: {
      type: [String, Number],
      default: "",
    },
    showMarginFactor: {
      type: Boolean,
      default: false,
    },
    marginFactor: {
      type: [String, Number],
      default: "",
    },
    showCustomTag: {
      type: Boolean,
      default: false,
    },
    customTagLabel: {
      type: String,
      default: "Custom tag name",
    },
    customTag: {
      type: String,
      default: "",
    },
    showStartDate: {
      type: Boolean,
      default: false,
    },
    startDateLabel: {
      type: String,
      default: "Pricing start date",
    },
    startDate: {
      type: String,
      default: "",
    },
    showEndDate: {
      type: Boolean,
      default: false,
    },
    endDateLabel: {
      type: String,
      default: "Pricing end date",
    },
    endDate: {
      type: String,
      default: "",
    },
    showImportCSVFile: {
      type: Boolean,
      default: false,
    },
    importCSVFileName: {
      type: String,
      default: "",
    },
    //language module code
    nameLabel: {
      type: String,
      default: "Name",
    },
    siteLabel: {
      type: String,
      default: "Selected sites",
    },
    chooseProductLabel: {
      type: String,
      default: "Choose products",
    },
    priceEndingLabel: {
      type: String,
      default: "Price Ending",
    },
    discountLabel: {
      type: String,
      default: "Discount",
    },
    marginFactorLabel: {
      type: String,
      default: "Margin Factor",
    },
    importedFromCSVLabel: {
      type: String,
      default: "Import dafa from CSV file",
    },
  },
  components: {
    FilteredTags,
  },
  data() {
    return {
      enableProductGroupBoxStyling: config.enableProductGroupBoxStyling,
      enableAddProductGroup: config.enableAddProductGroup,
    };
  },
  computed: {
    ...mapGetters(["productGroups", "selectedSite"]),
  },
};
</script>
