<template>
  <!--
      Tags Div for Product Group
    -->
  <div class="tags-group mt-2">
    <!-- Vendors tags -->
    <div
      class="tag tags_title"
      v-for="(tag, index) in vendors"
      :key="'vendor_' + index"
    >
      <div class="d-flex align-items-center">
        <span class="condition">
          {{ tag.condition }}
        </span>
        <span class="align-middle"> Vendor {{ tag.vendorName }} </span>
        <i
          class="icon-close ml-2"
          @click="deleteVendorFilter(tag)"
          v-if="removeTags"
          role="button"
        ></i>
      </div>
    </div>
    <!-- Category tags -->
    <div
      class="tag tags_title"
      v-for="(tag, index) in category"
      :key="'category_' + index"
    >
      <div class="d-flex align-items-center">
        <span class="condition float-start">{{ tag.condition }}</span>
        <span class="align-middle"> Category {{ tag.roomGrouping }} </span>
        <i
          class="icon-close ml-2"
          @click="deleteCategoryFilter(tag)"
          v-if="removeTags"
          role="button"
        ></i>
      </div>
    </div>
    <!-- Tags for ProductTags -->
    <div
      class="tag tags_title"
      v-for="(tag, index) in productTags"
      :key="'tag_' + index"
    >
      <div class="d-flex align-items-center">
        <span class="condition float-start">{{ tag.condition }}</span>
        <span class="align-middle"> Product Tag {{ tag.tag }} </span>
        <i
          class="icon-close ml-2"
          @click="deleteTagFilter(tag)"
          v-if="removeTags"
          role="button"
        ></i>
      </div>
    </div>
    <!-- Vendor Model Number tags -->
    <div
      class="tag tags_title"
      v-for="(tag, index) in vmn"
      :key="'vmn_' + index"
    >
      <div class="d-flex align-items-center">
        <span class="condition float-start align-middle">{{
          tag.condition
        }}</span>
        <span class="align-middle"> VMN {{ tag.productVendorModel }} </span>
        <i
          class="icon-close ml-2"
          @click="deleteVmnFilter(tag)"
          v-if="removeTags"
          role="button"
        ></i>
      </div>
    </div>
    <!-- SKU tags -->
    <div
      class="tag tags_title"
      v-for="(tag, index) in sku"
      :key="'sku_' + index"
    >
      <div class="d-flex align-items-center">
        <span class="condition float-start">{{ tag.condition }}</span>
        <span class="align-middle"> SKU {{ tag.productSKU }} </span>
        <i
          class="icon-close ml-2"
          @click="deleteSkuFilter(tag)"
          v-if="removeTags"
          role="button"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main";
export default {
  name: "ProductTags",
  props: {
    id: [String, Number],
    vendors: Array,
    category: Array,
    productTags: Array,
    vmn: Array,
    sku: Array,
    removeTags: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // deleteVendorFilter(data) {
    //   eventBus.$emit("deleteVendorFilter", { vendor: data, id: this.id });
    // },
    // deleteCategoryFilter(data) {
    //   eventBus.$emit("deleteCategoryFilter", { category: data, id: this.id });
    // },
    // deleteTagFilter(data) {
    //   eventBus.$emit("deleteTagFilter", { tag: data, id: this.id });
    // },
    // deleteVmnFilter(data) {
    //   eventBus.$emit("deleteVmnFilter", { vmn: data, id: this.id });
    // },
    // deleteSkuFilter(data) {
    //   eventBus.$emit("deleteSkuFilter", { sku: data, id: this.id });
    // },

    deleteVendorFilter(data) {
      this.deleteFilter(data, "vendorFilter");
    },
    deleteCategoryFilter(data) {
      this.deleteFilter(data, "categoryFilter");
    },
    deleteTagFilter(data) {
      this.deleteFilter(data, "productTagFilter");
    },
    deleteVmnFilter(data) {
      this.deleteFilter(data, "vmnFilter");
    },
    deleteSkuFilter(data) {
      this.deleteFilter(data, "skuFilter");
    },
    deleteFilter(data, filterName) {
      eventBus.$emit("deleteFilter", {
        id: this.id,
        filterData: {
          filterName: filterName,
          filter: data,
        },
      });
    },
  },
};
</script>
